@use 'styles/colors';
@import 'styles/breakpoints';

.modules {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  .module {
    overflow: hidden;
    background: #d9d9d9;
    padding: 0 1rem 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    position: relative;
    transition: max-height 0.5s ease;

    .collapse {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      .collapse-container {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        transform: scale(0.8);
        transition: all 0.3s ease-in-out;

        &.collapsed {
          transform: rotate(90deg) scale(0.7);
        }

        span {
          width: 1rem;
          height: 0.15rem;
          background: black;
          display: block;
          position: absolute;
          border-radius: 3px;

          &:nth-child(1) {
            transform: translate(-9%, 490%) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translate(57%, 490%) rotate(-45deg);
          }
        }
      }
    }

    .modules-header {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .flex-grow {
        flex-grow: inherit;
      }

      div:first-of-type {
        margin-right: 0.5rem;
      }
    }

    input[type='checkbox'] {
      display: none;
    }

    input:checked + label.switch {
      background: colors.$green;
    }

    input:checked + label.switch:after {
      top: 0.13rem;
      left: 1.5rem;
    }

    .switch {
      margin: 0.25rem 0;
      cursor: pointer;
      text-indent: -9999px;
      width: 2.8rem;
      height: 1.4rem;
      background: grey;
      display: block;
      border-radius: 1.5rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0.13rem;
        left: 0.2rem;
        width: 1.1rem;
        height: 1.1rem;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
        box-shadow: #919191 0 0 5px;
      }
    }

    .settings {
      label {
        font-weight: bold;
        display: block;

        input {
          border: 1px solid colors.$lightgray;
          padding: 0.25rem;
          border-radius: 3px;
          width: 100%;
          display: block;
          margin-top: 0.25rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .icon {
      display: flex;
      margin-bottom: -1rem;

      .form-single-image {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        label {
          &:after {
            width: 8rem;
            text-align: center;
            margin-top: -1.25rem;
          }
        }
      }

      .images {
        margin-left: 12rem;
        margin-bottom: 0;

        .image {
          max-width: 3rem;
          max-height: 4rem;
          width: 3rem;
          height: 4em;

          .content {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      input {
        display: none !important;
      }

      //.svg {
      //  width: 2.5rem;
      //  height: 2rem;
      //  background: white;
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //}

      button {
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.65rem;
        display: inline-block;
      }
    }
  }
}

.trash-icon {
  position: relative;
  top: -1.5rem;
  right: 1.5rem;
  svg:hover {
    cursor: pointer;
    fill: red;
  }
}