@use 'src/styles/preview';

.preview-media {
  display: flex;
  flex-direction: row;
  max-height: 11rem;
  video, img {
    flex: 1;
    max-width: 100%;
    object-fit: cover;
  }
   
}

.preview-interest {
  height: 100%;
  color: white;


  h1 {
    margin: calc(preview.$previewVh * 3) 0 preview.$previewVh 0;
    font-size: calc(preview.$previewVh * 3);
  }

  hr {
    width: calc(preview.$previewVh * 10);
    display: block;
    text-align: right;
    margin: calc(preview.$previewVh * 2) 0;
  }

  p {
    font-size: calc(preview.$previewVh * 1.5);
  }

  svg {
    padding: calc(preview.$previewVh * 1);
    background: white;
    margin-top: calc(preview.$previewVh * 2);
    height: calc(preview.$previewVh * 20);
    width: calc(preview.$previewVh * 20);
  }
}

.scroll-container {
  max-height: 5rem; /* Or any desired height */
  overflow-y: scroll; /* Use scroll to ensure scroll functionality */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  p {
    margin: 0;
    padding: 0;
  }
}

.scroll-container::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
}