.map-container {
  width: '72vw';
  height: '100%';
}

// media query for display bigger than 1200px

@media (min-width: 1200px) {
  .map-container {
    width: calc(100vw - 700px);
  }
}

// media query for display less than 1200px

@media (max-width: 1200px) {
  .map-container {
    width: 72vw;
  }
}