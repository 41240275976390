@use 'styles/colors';

.action-pane {
  background-color: colors.$darkgray;
  color: colors.$white;
  height: calc(100vh - 41px);
  padding: 16px 32px !important;
  position: fixed;
  right: 0;
  width: 100vw;
}

.expandable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.text-white {
  color: colors.$white;
}

.expandable-icon {
  height: 12px;
  width: 12px;
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

.expandable-content {
  padding: 5px 5px 5px 24px;
}

.selected {
  font-weight: bold;
}

.remove-padding-and-margin {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.floor {
  padding: 4px 0;
}

.selected-dropdown {
  background-color: rgba($color: colors.$lightgray , $alpha: 0.3);
  margin-top: 5px;
}

.action-pane::-webkit-scrollbar {
  display: none;
}

.overflow-content-map {
  max-height: 70vh;
  overflow-y: auto;
}

.overflow-content-map::-webkit-scrollbar {
  display: none;
}