@use 'styles/colors';

.action-pane-map {
    position: fixed;
    right: 0;
    background-color: colors.$darkgray;
    height: calc(100vh - 41.5px);
    // overflow-y: hidden;
    padding: 16px 32px !important;
    color: colors.$white;
    padding: 10px;
    width: 28vw !important;
}

.expandable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.text-white {
    color: colors.$white;
}

.expandable-icon {
    height: 12px;
    width: 12px;
}

.hide {
    display: none !important;
}

.show {
    display: block;
}

.expandable-content {
    padding: 5px 5px 5px 24px;
}

.expandable::-webkit-scrollbar {
    display: none;
}

.selected {
    font-weight: bold;
}

.remove-padding-and-margin {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.floor {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }
}

.selected-dropdown {
    background-color: rgba($color: colors.$lightgray , $alpha: 0.3);
    margin-top: 5px;
}

.delete-icon {
    svg {
        height: 14px;
        width: 14px;
        fill: colors.$white;
    }

    svg:hover {
        fill: colors.$red;
    }
}

.shared-icon {
    svg {
    height: 14px;
    width: 14px;
    // fill: colors.$white;
}
}


.overflow-content-map {
    max-height: 30vh;
    overflow-y: auto;
}

.overflow-content-map::-webkit-scrollbar {
    display: none;
}