.follow-div-street {
    width: 20px;
    height: 20px;
    background-color: #ff0000;
    position: absolute;
    border-radius: 50%;
    pointer-events: none; /* So it doesn't block the cursor */
    transform: "translate(-50%, -50%)";
  }

  .cancel-action {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 20000000;
    background-color: #ff0000;
    padding: 8px;
    min-width: 25rem;
    border-radius: 6px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 800;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }

  .position-relative {
    position: relative;
  }

  .setting-panel {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1000;
    background-color: #ffffff;
    padding: 8px;
    min-width: 20rem;
    border-radius: 6px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1{
        font-weight: 800;
        font-size: 14px;
        color: #000000;
      }
    }
  }