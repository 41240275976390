.modal-container {
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;

  .add-modal {
    .button-group {
      pointer-events: all;
      display: flex;
      flex-direction: row;
      justify-content: right;
      gap: 1rem;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .add-modal,
  .modal {
    position: fixed;
    pointer-events: all;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    padding-bottom: 0;
    border-radius: 3px;
    min-width: 400px;
    max-width: 600px;
    z-index: 2;

    .confirmation-text {
      padding: 1rem 0;
      margin: -1rem 0 0 0;

      p {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2em;
        margin: 0.5rem 0;
        font-weight: 400;
      }
    }

    .button {
      font-size: 0.8rem;
      padding: 0.5rem 2rem;
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
