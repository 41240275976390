@use 'styles/colors' as *;

* {
  box-sizing: border-box;
}

.map-grid {
  display: flex;
  max-width: calc(100vw - 17.45rem);
  @media screen and (max-width: 1200px) {
    max-width: calc(100vw - 0rem);
  }
  height: calc(100vh - 3.55rem);
  overflow: hidden;

  .map-container {
    flex: 1;
    max-width: calc(100vw - 34rem);
    @media screen and (max-width: 1200px) {
      max-width: calc(100vw - 25em);
    }
    @media screen and (max-width: 1200px) {
      max-width: calc(100vw - 18em);
    }
  }
}

.map-container {
  position: relative;

  .tweak-popup {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    background: $primary;
    z-index: 100;
    width: 60%;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    color: white;
    p {
      font-size: 1em;
    }
    button {
      background: $darkgray;
      padding: 0.35rem 1rem;
      margin-right: 0.25rem;
      margin-bottom: 0.75rem;
      border-radius: 3px;
      &:nth-child(3) {
        background: greenyellow;
        color: $darkgray;
      }
    }
    .split {
      width: 40%;
      float: left;
      padding: 1rem;
    }
    .tweaker {
      float: right;
      width: 55%;
      padding: 1rem;
      p {
        width: 80%;
        float: left;
        margin: 1.5rem 0;
      }
      .tweaker-parent {
        width: 4rem;
        height: 4rem;
        margin-top: 0.2rem;
        position: relative;
        float: right;
        button {
          margin: 0;
          width: 1rem;
          height: 1rem;
          padding: 0;
          background: none;
        }
        button svg {
          fill: white;
          width: 1rem;
          height: 1rem;
        }
        button:nth-child(1) {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          svg {
            transform: rotate(-90deg);
          }
        }
        button:nth-child(2) {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          svg {
            transform: rotate(90deg);
          }
        }
        button:nth-child(3) {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          svg {
            transform: rotate(-180deg);
          }
        }
        button:nth-child(4) {
          position: absolute;
          top: 50%;
          right: -1%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.controls {
  min-width: 18rem;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 3.45em);

  hr {
    width: 80%;
    margin: 1rem auto;
    border-color: $primary;
    background: $primary;
    border-width: 2px;
  }

  .button {
    display: block;
    width: 100%;
    background: $primary;
    padding: 0.5rem 1rem;
    border-radius: 3px;

    font-weight: 800;
    text-transform: uppercase;
    font-family: 'Montseratt', sans-serif;
    font-size: 0.8em;

    &.red {
      background: #e51515;
    }
  }

  .button-group {
    margin-top: 1rem;

    .button {
      display: inline-block;
      width: 48%;
      position: relative;
      &:nth-child(2) {
        float: right;
      }

      .loading-container {
        height: 1em;
        width: 1em;
        transform: scale(0.15);
        display: block;
        position: absolute;
        left: 9px;
        top: 4px;
      }
    }
  }
}
.mapContainer__inner {
  background: white;
  position: relative;
  display: block;
  transform-origin: center center;
  transition: scale 0.35s ease-in-out, transform 0s;
}

.flex {
  display: flex;
}

.simple-development-map {
  .react-transform-wrapper {
    width: 100% !important;
    height: calc(100vh - 3.45em) !important;
  }
  
  .react-transform-component {
    width: 2000px;
  }
  .mapContainer__inner {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.map-container {
  // width: 66vw;
  height: calc(100vh - 3.45em);
  overflow: hidden;
  background: $white;

  .error-cover {
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background: #333;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    z-index: 99;
  }

  .error-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -1000%);
    z-index: 100;
    background: $primary;
    p {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat', sans-serif;

      padding: 1em 2em;
    }
  }
}

.mapContainer__inner {
  width: 2000px;
  height: 100%;
}

.mapContainer__inner img {
  width: 2000px;
  height: 100%;
}

.point {
  opacity: 0.8;
  height: 10rem;
  width: 10rem;
  background: #dd1f64;
  display: block;
  border-radius: 100rem;
  transform: scale(0.13) translate(-350%, -350%);
  transition: all 0.5s ease;
  cursor: pointer;
  z-index: 10;

  &.number {
    font-size: 16rem;
    color: white;
    width: auto;
    height: 20rem;
    white-space: nowrap;
    transform: scale(0.13) translate(-380%, -1100%);
    border-radius: 5rem;
    padding: 0.1rem 6rem;
    //min-width: 50rem;

    &:after {
      content: '';
      background: #dd1f64;
      width: 10rem;
      height: 10rem;
      display: block;
      border-radius: 5rem;
      transform: translate(210%, 130%);
    }
  }

  &.quick {
    transition: all 0.05s ease;
    pointer-events: none;
  }

  &:before {
    transition: all 0.3s ease;
    opacity: 0;
  }

  &.active::before {
    //background: white;
    position: relative;
    opacity: 0.5;
    height: 9rem;
    width: 9rem;
    border: 4rem solid white;
    border-radius: 20rem;
    transform: translate(-18%, -18%);
    content: '';
    display: block;
    z-index: -2;
    animation: pulse-pin 2s ease-in-out infinite both;
  }

    &.street-pin {
      background: transparent;
      height: 24px;
      width: 24px;
      transform: translate(-25%, -25%); // Simpler transform for street pins
  
      .street-icon {
        width: 100%;
        height: 100%;
        fill: red;
        position: absolute;
        top: 0;
        left: 0;
      }
  
      &.active {
        z-index: 11;
  
        &::before {
          content: '';
          position: absolute;
          top: 25%;
          left: 20%;
          width: 24px;
          height: 24px;
          border: 4px solid red;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          animation: pulse-pin 2s ease-in-out infinite both;
        }
      }
    }
  //
  @keyframes pulse-pin {
    0% {
      transform: translate(-21%, -21%) scale(1);
    }
    50% {
      transform: translate(-21%, -21%) scale(1.1);
    }
    100% {
      transform: translate(-21%, -21%) scale(1);
    }
  }

  &.reserved {
    background: #1b1b1d;
  }

  &.future {
    background: greenyellow;
  }

  &.sold {
    background: #0cb1b1;
  }
}

body {
  display: flex;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
}
.controls {
  background: $darkgray;

  .loading-container {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
      font-size: 2em;
      margin-top: 2em;
    }
  }

  .loading-cover {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000000bf;
  }

  .header {
    margin: 0;
    .button {
      width: auto;
    }
  }
}
.App {
  display: flex;
  width: 100%;
}
#root {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.82);
  width: 100vw;
  height: 100vh;
  content: '';
  z-index: 10;
}

.form {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  padding: 0 0.5rem 0.5rem 0.5rem;
  width: 15rem;

  &.modal {
    width: 40rem;
  }
}

.form input,
.form button,
.form h4 {
  display: block;
  padding: 0.25rem 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0.5rem 0;
}

.form input {
  border-radius: 2px;
  border: 2px solid #e0e0e0;
}

.form button {
  border: none;
  background: $primary;
  color: white;
  padding: 0.5rem;
}

.loginState {
  color: #dd1f64;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bolder;
  padding: 0;
}

.plot {
  width: 100%;
  padding: 0.75rem 0 0.75em 2em;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #28282b;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  h4 {
    padding: 0;
    margin: 0.25rem 0;
    color: white;
    float: left;
    text-transform: capitalize;
  }

  button {
    float: right;
  }

  svg {
    fill: white;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    float: left;
    margin: 0rem 0.5rem 0.25rem 0;
  }

  span.incomplete {
    float: right;
    color: white;
    font-family: 'Karla', sans-serif;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    background: $primary;
    border-radius: 2px;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 0.75em;
    text-transform: uppercase;
  }
}
button {
  border: none;
  color: white;
  background: none;
  margin-right: 0.1rem;

  svg,
  p {
    margin: 0.1rem 0.5rem 0.25rem 0;
  }

  p {
    font-size: 0.8rem;
    font-weight: bolder;
    background: black;
    padding: 0 0.3rem;
  }
}

.popup-container {
  position: absolute;
  padding: 1rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .move-popup {
    transform: translate(calc(-50% - 200px), 0);
    z-index: 9;
    background: #dd1f64;
    padding: 1rem;
    border-radius: 3px;
  }
}

.move-popup p {
  margin: 0;
  padding: 0;
  color: snow;
  border-radius: 3px;
}

.header {
  padding: 1rem;
}
.developments-selector {
  padding: 0 1rem;
}
.developments-selector select {
  width: 100%;
  border-radius: 2px;
  border: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
}
.developments-selector button#plotplots-add-button {
  margin-top: 0;
  margin-bottom: 1rem;
}

.add-plot {
  padding: 1rem;
  position: relative;
}
.add-plot .loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-plot .loading-cover h3 {
  display: block;
}
.add-plot input,
.add-plot select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  margin-top: 0.255rem;
  padding: 0.25rem;
}

.add-plot button {
  float: right;
  margin-top: -0.25rem;
}

.add-plot h3 {
  margin: 0em 0 0.75rem 0;
}

.add-plot {
  overflow: hidden;
  color: white;
  padding: 1rem;
  background: black;
}

.add-plot p {
  font-size: 0.8rem;
  margin-top: 1rem;
}

#plots-add-button {
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.plot-info {
  margin: 0 1rem;
}
.plot-info h3,
.plot-info h5,
.plot-info h1 {
  color: white;
  margin: 1rem 0 0.25rem;
}

.plot-info h5 {
  font-size: 0.8rem;
}

.plot-info input,
.plot-info select {
  background: #ffffff;
  display: block;
  width: 100%;
  padding: 0.7rem;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: #5c5c5c;
  &:focus {
    border-color: $primary;
    outline: none;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 60px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.header {
  width: 100%;

  h1 {
    display: inline-block;
    margin: 1rem 0 0 0;
    color: white;
    font-family: 'Montseratt', sans-serif;

    .plots-total {
      font-weight: normal;
      color: lighten($lightgray, 30%);
    }
  }

  .button {
    float: right;
    background: $primary;
    margin: 0.9rem 0 0 0;
    display: inline-block;
    height: 1.85rem;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: white;
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.filter {
  padding: 0 1rem;
  input {
    border: 2px solid white;
    border-radius: 3px;
    width: 100%;
    padding: 0.5rem;
    outline: none;
    margin: 0 0 1rem 0;
    transition: all 0.25s ease;
    &:focus {
      border-color: #dd1f64;
    }
  }
}

span.plot-header {
  position: relative;
  display: block;
  width: 100%;
  background: #3d3d42;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  color: white;
  font-family: 'Montseratt', sans-serif;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8em;
  span {
    user-select: none;
  }
  .plots-total {
    font-weight: normal;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  span.icon {
    position: absolute;
    right: 1.1rem;
    top: 0.75rem;
    transition: all 0.4s ease;
    &:after {
      width: 1em;
      height: 2px;
      content: '';
      display: block;
      background: white;
    }
    &:before {
      transition: all 0.3s ease;
      width: 1em;
      height: 2px;
      content: '';
      display: block;
      background: white;
      transform: rotate(90deg) translate(2px, 0);
      opacity: 1;
    }
    &.collapse {
      &:before {
        opacity: 0;
        transform: rotate(0deg) translate(0, 2px);
      }
    }
  }
}

.plot-saving {
  display: flex;
  height: 2em;
  margin: 1em 0;
  .sk-chase {
    display: inline-block;
    width: 2em;
    height: 2em;
  }
  p {
    display: inline-block;
    margin: 0 0 0 1rem;
    align-self: center;
  }
}

.price-input {
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    position: absolute;
    content: '';
    left: 0.75rem;
    left: 12px;
    top: 12;
    bottom: 0;
    width: 10px;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODM2MDQgMTRIMC4wMzc5NDA0VjEyLjUxMDhIOS44MzYwNFYxNFpNNi4zNzM5OCA4LjA5MDc5SDBWNi42MDE2M0g2LjM3Mzk4VjguMDkwNzlaTTMuMjYyODcgNC4xNDQ5OUwzLjQ3MTU0IDEwLjE4N0MzLjQ3Nzg3IDEwLjc4MTQgMy4zOTI1IDExLjMzNzkgMy4yMTU0NSAxMS44NTY0QzMuMDM4MzkgMTIuMzY4NiAyLjc3MjgxIDEyLjc1NDMgMi40MTg3IDEzLjAxMzZMMC43NjgyOTMgMTIuNTEwOEMwLjk4OTYxMiAxMi40NTM5IDEuMTYzNSAxMi4yOTU4IDEuMjg5OTcgMTIuMDM2NkMxLjQyMjc2IDExLjc3MSAxLjUxNDQ1IDExLjQ2NzUgMS41NjUwNCAxMS4xMjZDMS42MjE5NSAxMC43ODQ2IDEuNjUwNDEgMTAuNDc0NyAxLjY1MDQxIDEwLjE5NjVMMS40NDE3MyA0LjE0NDk5QzEuNDQxNzMgMy4yNzIzNiAxLjYyNTExIDIuNTI5MzYgMS45OTE4NyAxLjkxNTk5QzIuMzU4NjMgMS4yOTYzIDIuODU4MTggMC44MjIwNDIgMy40OTA1MSAwLjQ5MzIyNUM0LjEyOTE4IDAuMTY0NDA4IDQuODUwMDUgMCA1LjY1MzEyIDBDNi40ODE0OCAwIDcuMTg2NTQgMC4xNTQ5MjMgNy43NjgyOSAwLjQ2NDc3QzguMzUwMDUgMC43NzQ2MTYgOC43OTU4NSAxLjIwNzc3IDkuMTA1NjkgMS43NjQyM0M5LjQxNTU0IDIuMzE0MzYgOS41NzA0NiAyLjk1NjE5IDkuNTcwNDYgMy42ODk3SDcuNzQ5MzJDNy43NDkzMiAzLjE1ODU0IDcuNjQxODIgMi43MzE3MSA3LjQyNjgzIDIuNDA5MjFDNy4yMTgxNiAyLjA4NjcyIDYuOTQzMDkgMS44NTI3NiA2LjYwMTYzIDEuNzA3MzJDNi4yNjAxNiAxLjU2MTg4IDUuODkzNDEgMS40ODkxNiA1LjUwMTM2IDEuNDg5MTZDNS4wODQwMSAxLjQ4OTE2IDQuNzA0NjEgMS41OTM1IDQuMzYzMTQgMS44MDIxN0M0LjAyOCAyLjAwNDUyIDMuNzU5MjYgMi4zMDQ4OCAzLjU1NjkxIDIuNzAzMjVDMy4zNjA4OSAzLjA5NTMgMy4yNjI4NyAzLjU3NTg4IDMuMjYyODcgNC4xNDQ5OVoiIGZpbGw9IiMzRjNGM0YiLz4KPC9zdmc+Cg==')
      center / contain no-repeat;
  }
  input {
    &:hover {
      border-color: #5c5c5c;
    }
    &:focus {
      border-color: $primary !important;
    }
  }
  input {
    padding-left: 2em;
  }
}

.key {
  position: absolute;
  bottom: 1em;
  left: 1em;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  padding: 0.5em;
}
.key-item {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0.5em;

  &.reserved {
    &:before {
      background: #1b1b1d;
    }
  }

  &.future {
    &:before {
      background: greenyellow;
    }
  }

  &.sold {
    &:before {
      background: #0cb1b1;
    }
  }

  &:before {
    content: '';
    width: 1em;
    height: 1em;
    background: $primary;
    display: inline-block;
    border-radius: 1em;
    margin-bottom: -0.2em;
    margin-right: 0.5em;
  }
}

.button {
  cursor: pointer;

  &.button-gray {
    background: $lightgray;
  }

  &.button-active {
    background: $lightblue;
  }
}

.message {
  background: black;
  color: white;
  margin-top: 0.5em;
  padding: 0 0 0 2em;
  margin-left: -2em;
  margin-right: -2em;
  overflow: hidden;
  box-sizing: border-box;
  &.success {
    background: green;
  }
  &.error {
    background: red;
  }
}
//
//.location-divider {
//  background: black;
//  margin-top: 2em;
//  margin-left:  -1rem;
//  margin-right: -1rem;
//  padding: 0.25em 1rem 2em;
//}

.plots-list-collapse {
  display: block;
  overflow: hidden;
}

#map {
  .loading-cover {
    position: fixed;
    top: 0;
    left: 19em;
    width: calc(100vw - 19em);
    height: 100vh;
    background: white;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-bar {
      position: relative;
      overflow: hidden;
      width: 15em;
      height: 0.75em;
      background: white;
      .bar {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        height: 0.75em;
        width: 3em;
        background: $primary;
        animation: laoding-bar 1.3s linear;
        animation-iteration-count: infinite;
      }
    }
  }
}

@keyframes laoding-bar {
  0% {
    left: -20%;
  }
  100% {
    left: 100%;
  }
}

.alert {
  p {
    color: white;
    font-weight: bolder;
    padding: 0;
    margin-top: 0.2rem;
    border-radius: 3px;
  }
}
