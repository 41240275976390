@use 'styles/colors';

.options {
    background-color: colors.$white;
    border: 1px solid colors.$bordergray;
    border-radius: 0.4vh;
    padding: 20px;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    p {
      font-size: 1.2em;
      font-weight: 800;
      text-transform: uppercase;
      color: colors.$darkgray;
      margin: 0;
    }
    .option-icons {
      display: flex;
      flex-direction: row;
      svg {
        height: 24px;
        width: 24px;
        margin: 0 5px;
        cursor: pointer;
      }
      .shared-icon .delete:hover {
        fill: colors.$red;
      }
    }
  }