.main-content-sizing {
    width: calc(100% - 45px) !important;
    height: calc(100vh - 57px) !important;
}

//media query less than 1200px

@media (max-width: 1200px) {
    .main-content-sizing {
        width: 102% !important;
    }
}

.buttonWithIcon{
    width: '3rem';
    height: '3rem';
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    border-radius: '50%';
    background-color: 'rgb(55, 141, 177)';
    border: '2px solid #fff';
    cursor: 'pointer';
}

.set-angle{
    font-size: '12px';
    cursor: 'pointer';
    background: 'black';
    padding: 4px 2px;
}
  