@use 'styles/colors';

.back-icon {
    cursor: 'pointer'; 
    color: colors.$primary;
    position: absolute;
    right: 40px
}

h2 {
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
}

// .save-btn {
//     margin-top: 12px;
// }